<template>
  <v-container fluid class="down-top-padding">
    <div class="notification">
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            outlined
            deletable-chips
            v-model="user_ids"
            :items="getMembersList"
            item-text="companyRepName"
            item-value="id"
            :label="$t('notification.members')"
            :placeholder="$t('notification.members')"
            @input="$v.user_ids.$touch()"
            @blur="$v.user_ids.$touch()"
            @change="selectAll"
            :error-messages="userIdsErrors"
            multiple
            chips
            hide-details="auto"
            return-value
            ><template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 5">
                <span>{{ item.companyRepName }}</span>
              </v-chip>
              <span v-if="index === 5" class="grey--text text-caption">
                (+{{ user_ids.length - 5 }} others)
              </span>
            </template></v-autocomplete
          >
        </v-col>
        <v-col cols="6"> </v-col>
        <v-col cols="6">
          <v-select
            outlined
            v-model="type"
            :items="operationList"
            item-text="name"
            item-value="value"
            :label="$t('notification.operation')"
            hide-details="auto"
            :placeholder="$t('notification.operation')"
            @input="$v.type.$touch()"
            @blur="$v.type.$touch()"
            :error-messages="operationErrors"
            return-value
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex" cols="12" sm="12" xsm="12">
          <v-btn
            @click="sendNotification"
            :loading="saving"
            :disabled="($v.$invalid && $v.$anyDirty) || saving"
            color="#1b5e20"
            class="white--text"
            hide-details="auto"
            large
            >{{ $t("notification.send") }}</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { showSnackbar } from "../../eventbus/action.js";
export default {
  name: "Notification",
  data() {
    return {
      saving: false,
      operationList: [
        {
          name: "Reload",
          value: "reload",
        },
        {
          name: "Logout",
          value: "logout",
        },
      ],
      type: "",
      user_ids: [],
      errors: {
        type: null,
        user_ids: null,
      },
    };
  },
  validations: {
    type: { required },
    user_ids: { required },
  },
  computed: {
    ...mapGetters({
      members: "members/getMembersAssociateList",
    }),
    getMembersList() {
      if (this.members.length === this.user_ids.length) {
        return [
          {
            id: "unSelectAll",
            companyRepName: "Unselect All",
          },
          ...this.members,
        ];
      } else {
        return [
          {
            id: "selectAll",
            companyRepName: "Select All",
          },
          ...this.members,
        ];
      }
    },
    userIdsErrors() {
      const errors = [];
      if (!this.$v.user_ids.$dirty) return errors;
      !this.$v.user_ids.required &&
        errors.push(this.$t("notification.validations.userIdIsRequired"));
      return errors;
    },
    operationErrors() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;
      !this.$v.type.required &&
        errors.push(this.$t("notification.validations.typeIsRequired"));
      return errors;
    },
  },
  mounted() {
    this.getMembers();
  },
  methods: {
    ...mapActions({
      getMembers: "members/getMembersAssociateList",
      login: "user/login",
      send: "notification/sendNotification",
    }),

    selectAll() {
      if (this.user_ids.includes("selectAll")) {
        let allMembers = this.members.map((val) => val.id);
        this.user_ids = [...allMembers];
      }
      if (this.user_ids.includes("unSelectAll")) {
        this.user_ids = [];
      }
    },

    async sendNotification() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.saving = true;
          await this.send({
            type: this.type,
            user_ids: this.user_ids,
          });
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showSnackbar(errorMessage);
        } finally {
          this.saving = false;
        }
      }
    },
  },
};
</script>
