import Vue from "vue";

export const ACTIONS = {
  SNACKBAR: "snackbar",
  PARTICIPANT_GRID: "participant_grid",
};

const EventBus = new Vue();

export default EventBus;
